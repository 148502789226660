/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, March 05, 2018
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import PropTypes from "prop-types";
import Loader from "ae-base/Base/Loader";
import CardPage from "../../../Layout/CardPage";
import debounce from "lodash.debounce";

import Form from "ae-forms";
import { View, Text } from "react-native";

export default function ManagedGroupFormSubmissionCreation(props, context) {
	var model = props.model;
	var screen = model.size;
	var dispatch = context.dispatch;
	var localize = context.localize;
	var localizeFrom = context.localizeFrom;

	var saving = model.saving;
	var manage = model.manage;
	var managedgroupformlist = manage.managedgroupformlist;
	var account = manage.account;
	var formId = manage.managedgroupformsubmissions.form;

	if (!formId) return <Loader />;
	var currentForm = managedgroupformlist[formId];

	var schema = currentForm.schema;
	const isStaff = model.personal?.type?.includes("staff") || false;
	var hasSchema = schema && schema.properties;

	if (!hasSchema) return <Loader />;

	var localization = currentForm.localization;
	var schemaTitle = localizeFrom(localization, ".") || currentForm.name || currentForm._id;

	var title = `${schemaTitle} - ${localize("groupforms.addnew")}`;
	var discardchanges = localize("groupforms.discardchanges");
	var emrId = account.patientNumber;
	var prepopulateData = manage.managedactivegroupformprepopulate;

	if (!prepopulateData) return <Loader />;

	const clearPersistStorage = () => {
		dispatch("persiststorage:delete")({
			dataId: formId,
			targetId: manage.account._id,
		});
		manage.managedactivegroupformsubmission.persistStorage = undefined;
	};

	const onSubmit = (data) => {
		clearPersistStorage();
		dispatch("managedactivegroupformsubmission:trigger:submit")(data);
	};

	const goBack = () => {
		clearPersistStorage();
		dispatch("managedactivegroupformsubmission:trigger:cancel")();
	};

	const saveEncryptStorage = (data) => {
		schema.persistStorage = data;
		dispatch("persiststorage:save")({
			dataId: formId,
			targetId: manage.account._id,
			data,
		});
	};

	const defaultData = { ...prepopulateData, ...schema.persistStorage };
	const accountName = account?.fname + ' ' + account?.lname || "";
	const styles = {
		container: {
			flex: 1,
			justifyContent: "flex-end",
			alignItems: "flex-end",
		},
		patientDetails: {
			flexDirection: "row",
			alignItems: "center",
		},
		text: {
			color: "#065B61",
			fontWeight: "bold"
		}
	};

	const patientDetails = (
		<View id="patient_details" className="patient-details" style={styles.patientDetails}>
			<Text style={styles.text}> {localize("groupforms.patient")}: {accountName} </Text>
			{emrId ? <Text style={styles.text}> {localize("groupforms.emrId")}: {emrId} </Text> : null}
		</View>
	);

	return (
		<CardPage title={title} icon="notebook" saving={saving} screen={screen} toast={model.toast} online={model.online}>
			<View style={styles.container}>
				<View style={styles.patientDetails}>{patientDetails}</View>
			</View>
			<Form
				schema={schema}
				saveEncryptStorage={debounce((data) => saveEncryptStorage(data), 500)}
				dataId={formId}
				defaultData={defaultData}
				onSubmit={onSubmit}
				onCancel={goBack}
				cancelText={discardchanges}
				showCustomAddButton={isStaff}
			/>
		</CardPage>
	);
}

ManagedGroupFormSubmissionCreation.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func,
	localizeFrom: PropTypes.func,
};
