/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import PropTypes from "prop-types";
import React from "react";
import {
	View,
	Text
} from "react-native";
import prop from "prop";

import MessagingHeader from "./MessagingHeader";
import MessagingInput from "./MessagingInput";
import Messages from "./Messages";
import Message from "./Message";
import MessageScroll from "../../Layout/MessageScroll";
import Divider from "../../Layout/Divider";
import PageContainer from "../../Layout/PageContainer";
import StyledButton from "ae-base/Base/StyledButton";
import StyledText from "ae-base/Base/StyledText";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import BlockView from "ae-base/Base/BlockView";
import FileUploadDialog from "./FileUploadDialog";
import OfflineBanner from "../Homepage/OfflineBanner";
import UploadConfirm from "./UploadConfirm";

import Style from "@aetonix/styles";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		messages: {
			marginHorizontal: Style.layout.marginContent / 2
		},
		messagesPending: {
			justifyContent: "center"
		},
		divider: {
			marginHorizontal: Style.layout.marginLarge,
		},
		more: {
			marginVertical: Style.layout.marginContentVertical / 2,
			alignItems: "center",
			justifyContent: "center"
		},
		messageRead: {
			alignItems: "flex-end",
			marginHorizontal: Style.layout.marginContent / 2,
			padding: Style.layout.paddingSlim
		}
	},
	512: {
		messages: {
			marginHorizontal: Style.layout.marginContent
		},
		messageRead: {
			marginHorizontal: Style.layout.marginContent
		},
		more: {
			marginVertical: Style.layout.marginContentVertical,
			alignItems: "center",
			justifyContent: "center"
		}
	}
});

export default function Messaging(props, context) {
	var model = props.model;
	var localize = context.localize;
	var dispatch = context.dispatch;
	var saving = model.saving;
	var messages = model.messages;
	var uploading = messages.uploading;
	var fromInfo = messages.from || {} ;
	var to = messages.to || {};
	var file = messages.file;
	var image = messages.fileImage;
	var personal = model.personal;
	var groupedMsgList = model.groupedmessages || [];
	var loadmore = localize("timeline.loadmore");
	var getMore = dispatch("messages:trigger:next");
	var loadMoreVal = messages.loadMore ;
	var isAvailable = messages.isAvailable;
	var pendingMessages = messages.pendingList;
	var toast = model.toast;
	var id = fromInfo._id;
	var name = fromInfo.lname + ", " + fromInfo.fname;
	var lastMessage;
	var contactSettings = model.contactsettings || {};
	var fromSettings = contactSettings[fromInfo._id] || {};
	var contacts = model.allContacts || [];
	const disabledMessagingText = localize("messaging.disabledMessage");

	var screen = model.size;

	var renderedGroupedMessages = groupedMsgList
		.filter(prop("length"))
		.map(function(list){
			var from = list[0].from;
			var isAlternate = from !== personal._id;
			var person = isAlternate ? fromInfo : to;
			lastMessage = isAlternate ? null : list[list.length - 1];

			return (
				<Messages person={person} alternate={isAlternate} messages={list} screen={screen} key={list[0]._id} />
			);
		});

	var renderedPendingMessages = null;
	if(!saving)
		renderedPendingMessages = pendingMessages.map(function(message){
			return (
				<Message file={message.file} content={message.message} pending={true} />
			);
		});

	var renderMore = loadMoreVal ? (<View style={styles.more}>
		<StyledButton id={"LoadMore"} title={loadmore} icon="chevron-circle-up" onPress={getMore} />
	</View>) : null;

	var sendMessage = function(content){
		dispatch("messages:trigger:send", {
			message: content
		})();
	};
	// eslint-disable-next-line
	var onUpload = function(uploadFile){
		dispatch("messages:trigger:upload:confirm", {
			file: uploadFile
		})();
	};

	var cancelUpload = dispatch("messages:trigger:upload:cancel");
	var startUpload = dispatch("messages:trigger:upload:start");

	var offline = model.online ? null : (
		<OfflineBanner />
	);

	var renderDialog = null;
	if(uploading)
		renderDialog = (
			<FileUploadDialog onUpload={onUpload} cancelUpload={cancelUpload} />
		);

	var renderMessageRead = null;
	if(lastMessage && lastMessage.read){
		var readMessage = localize("messaging.readmessage", lastMessage);
		renderMessageRead = (<StyledText black small>{readMessage}</StyledText>);
	}
	var onConfirm = dispatch("messages:trigger:upload:finish");
	var onCancel = dispatch("messages:trigger:upload:cancel");

	var isContact = contacts.find((contact) => (contact._id === fromInfo._id));
	var disableVoiceVideo = !isContact || !isAvailable;
	var contactprofile = model.contactprofile;
	var allowMessage = contactprofile.allowMessage;
	var defaultMessage = model?.messages?.defaultMessage;
	const messagingDisabled = fromSettings?.message; 

	var renderMessagingInput = (allowMessage || isContact) && !messagingDisabled ? (
		<MessagingInput startUpload={startUpload} messages={messages} defaultMessage={defaultMessage} name={name} id={id} sendMessage={sendMessage} />
	) : null;

	const displayMessage = messagingDisabled ? (
		<Text
			style={{
				textAlign: "center",
				backgroundColor: Style.colors.grey,
				fontSize: 20,
				color: Style.colors.black,
				height: 50,
			}}
		>
			{disabledMessagingText}
		</Text>
	) : null;

	return (
		<PageContainer saving={saving} toast={toast}>
			<MessagingHeader model={model} messages={messages} disabled={disableVoiceVideo} contactSettings={fromSettings} />
			{offline}
			<MessageScroll>
				{renderMore}
				<View style={styles.messages} >
					{renderedGroupedMessages}
				</View>
				<View style={styles.messagesPending} >
					{renderedPendingMessages}
				</View>
				<View style={styles.messageRead} >
					{renderMessageRead}
				</View>
			</MessageScroll>
			<BlockView style={styles.divider}>
				<Divider />
			</BlockView>
			{renderMessagingInput}
			{displayMessage}
			{renderDialog}
			<UploadConfirm open={!!file} file={file} image={image} toast={toast} onConfirm={onConfirm} onCancel={onCancel} screen={screen} />
		</PageContainer>
	);
}

Messaging.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object,
	localize: PropTypes.func,
};
