import PropTypes from "prop-types";
import React from "react";
import {
	View,
	TouchableOpacity,
} from "react-native";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";

import Style from "ae-style";
import StyledText from "ae-base/Base/StyledText";
import StyledIconButton from "ae-base/Base/StyledIconButton";

var styles = ResponsiveStylesheet.createSized("min-direction", {

	0: {
		base: {
			flex: 1,
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",
			borderColor: Style.colors.black,
			padding: Style.layout.paddingSmall,
			marginVertical: 1
		},
		baseUnread: {
			backgroundColor: Style.colors.itemBlue
		},
		mainContent: {
			flexDirection: "row",
			flex: 1,
		},
		content: {
			flexDirection: "column",
			flex: 1,
			maxWidth: Style.layout.maxContent,
		},
		date: {
			flex: 1,
			alignItems: "flex-end"
		},
		icon: {
			marginRight: Style.layout.marginContentVertical,
		},
	},
	512: {
		base: {
			paddingHorizontal: Style.layout.squareButton,
		},
	}
});

export default function TimelineItem(props, context){
	var dispatch = context.dispatch;

	var notification = props.notification;
	var children = props.children;
	var iconName = props.iconName;

	var timestamp = notification.updated_at;
	var datestamp = notification.formatted_date;
	var from = notification.from;
	var fromID = notification._id;
	var type = notification.type;
	var group = notification.group;
	var name = "";
	var notificationcontentdisplay = props.content;

	if (Array.isArray(from)) {
		name = from[0].lname + ", " + from[0].fname + " & " + from[1].lname + ", " + from[1].fname ;
	}
	else if(type.indexOf("groupmessage") !== -1) {
		name = group.name;
	} else {
		name = from.lname + ", " + from.fname;
	}

	var clearable = props.clearable;

	var baseStyle = [styles.base];

	var content = (
		<View style={styles.mainContent}>
			<View style={styles.content}>
				<StyledText >{name} </StyledText>
				<StyledText small black numberOfLines={2} ellipsizeMode={"tail"}>{notificationcontentdisplay}</StyledText>
				{children}
			</View>
			<View style={styles.date}>
				<StyledText>{timestamp}</StyledText>
				<StyledText>{datestamp}</StyledText>
			</View>
		</View>
	);

	var wrappedContent;

	if(!notification.read){
		baseStyle.push(styles.baseUnread);
		if(clearable)
			var markAsRead = dispatch("usernotifications:trigger:clear", {
				from: fromID
			});
		wrappedContent = (
			<TouchableOpacity accessibilityComponentType="button" style={styles.mainContent} onPress={markAsRead}>
				{content}
			</TouchableOpacity>
		);
	} else {
		wrappedContent = (
			<View style={styles.mainContent}>
				{content}
			</View>
		);
	}

	return (
		<View style={baseStyle}>
			<StyledIconButton style={styles.icon} icon={iconName} hugeHeading onPress={props.onPress} />
			{wrappedContent}
		</View>
	);
}

TimelineItem.contextTypes = {
	dispatch: PropTypes.func
};
