import React from "react";
import {
	View,
	StyleSheet,
	Dimensions
} from "react-native";

import PropTypes from "prop-types";
import Form from "ae-forms";
import Card from "../../../Layout/Card";
import Modal from "../../../Layout/Modal";
import Heading from "ae-base/Base/Heading";

export default function ReminderView(props, context) {
	var model = props.model;
	var reminder = model.reminders || {};
	var dispatch = context.dispatch;
	var responseType = reminder.responseType;
	var content = reminder.content;

	var localize = context.localize;
	var screen = model.size;
	var width = screen.width;
	var accept = localize("atouchawayreminder.accept");
	var deny = localize("atouchawayreminder.deny");
	var cancel = localize("atouchawayreminder.cancel");
	var title = localize("atouchawayreminder.title");

	var onAccept = data => dispatch("ownactivereminder:trigger:respond", {
		action: data.action === "yes" ? "accept" : "deny"
	})();

	var onEmotion = data => dispatch("ownactivereminder:trigger:respond", {
		action: "respond",
		response: data.response === "yes" ? "😄" : "😦"
	})();

	var onYesNo = data => dispatch("ownactivereminder:trigger:respond", {
		action: "respond",
		response: data.response === "yes" ? "Yes" : "No"
	})();

	var onRangeAccept = dispatch("ownactivereminder:trigger:respond");

	var schema = null;
	var onSubmit = null;

	if(responseType === "confirm" || !responseType){
		schema = {
			"properties": {
				"action": {
					"type": "string",
					"inputType": "buttons",
					"value_localization": {
						"yes": accept,
						"no": deny
					}
				},
			},
			"required": ["action"]
		};
		onSubmit = onAccept;
	} else if(responseType === "emotion"){
		schema = {
			"properties": {
				"response": {
					"type": "string",
					"inputType": "buttons",
					"value_localization": {
						"yes": "😄",
						"no": "😦"
					}
				},
				"action": {
					"type": "string",
					"inputType": "hidden",
					"default": "respond"
				},
			},
			"required": ["response"]
		};
		onSubmit = onEmotion;
	} else if(responseType === "range") {
		schema = {
			"properties": {
				"response": {
					"type": "number",
					"minimum": 1,
					"inputType": "scale",
				},
				"action": {
					"type": "string",
					"inputType": "hidden",
					"default": "respond"
				},
			},
			"required": ["response"]
		};
		onSubmit = onRangeAccept;
	} else if(responseType === "yesorno"){
		schema = {
			"properties": {
				"response": {
					"type": "string",
					"inputType": "buttons",
					"value_description": "common"
				},
				"action": {
					"type": "string",
					"inputType": "hidden",
					"default": "respond"
				},
			},
			"required": ["response"]
		};
		onSubmit = onYesNo;
	} else if(responseType === "0range") {
		schema = {
			"properties": {
				"response": {
					"type": "string",
					"inputType": "scale",
					"enum": ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
				},
				"action": {
					"type": "string",
					"inputType": "hidden",
					"default": "respond"
				},
			},
			"required": ["response"]
		};
		onSubmit = onRangeAccept;
	}

	var heading = title ? (
		<Heading title={content} screen={screen} />
	) : null;

	var renderResponse = schema ? (
		<Form
			key={responseType}
			schema={schema}
			onSubmit={onSubmit}
			onCancel={dispatch("ownactivereminder:trigger:clear")}
			cancelText={cancel}
		/>
	) : null;

	return (
		<Modal open>
			<Card scroll>
				{heading}
				{renderResponse}
			</Card>
		</Modal>
	);
}

ReminderView.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
