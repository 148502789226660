import PropTypes from "prop-types";
import React, {
	PureComponent,
	Fragment,
	useContext
} from "react";
import {
	StyleSheet,
	TouchableOpacity
} from "react-native";

import StyledIconButton from "ae-base/Base/StyledIconButton";
import Contact from "../../Contacts/Contact";
import NotificationIcon from "ae-base/Base/NotificationIcon";
import getVersion, { NewCallingVersion } from "../../../utils/GetVersion";
import MeetingContext from "../../../Calling/context/MeetingContext.js";
import useEnabledFeatures from "../../../hooks/useEnabledFeatures/useEnabledFeatures";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { createStyled } from '@emotion/primitives-core'
import { faPhone, faPhoneSlash, faVideo, faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { getUserCallRoles } from "../../../Calling/libraries/users.library";
import useSelf from "../../../hooks/useSelf/useSelf";


var styles = StyleSheet.create({
	badge: {
		position: "absolute",
		right: 0,
		top: 0
	}
});
const styled = createStyled(StyleSheet);

const CallButton = styled(TouchableOpacity)({
	paddingLeft: 10
})

export default function ManagedContact(props, context){
	const config = context.config;
	var screen = props.screen;

	var contact = props.contact;
	var status = props.status;
	var id = contact._id;
	var missed = props.missed;
	var isAvailable = props.isAvailable;
	var settings = props.settings || {};
	const group = props.group;

	const self = useSelf();
	const { isCallingEnabled } = useEnabledFeatures();

	var openProfile = context.dispatch("contactprofile:trigger:profile:open", {
		who: id,
		group
	});

	var callContact = context.dispatch("calling:trigger:call", {
		who: id,
		reason: "screen",
	});

	var voiceCallContact = context.dispatch("calling:trigger:call", {
		who: id,
		reason: "screen",
		voiceOnly: true,
	});

	var renderMissedVideoCall = null;
	if(missed && missed.calls){
		renderMissedVideoCall = (
			<NotificationIcon style={styles.badge} title={missed.calls} />
		);
	}

	var renderMissedVoiceCall = null;
	if (missed && missed.voiceCalls) {
		renderMissedVoiceCall = (
			<NotificationIcon style={styles.badge} title={missed.voiceCalls} />
		);
	}

	var color = null;
	if(status === 2)
		color = "#bf3d3d";
	else if(status === 1)
		color = "#FFD40A";
	else if(status === 0)
		color = "#008a28";

	const version = getVersion(contact.versionInfo || {});
	const meetingContext = useContext(MeetingContext);
	let useOldCalling = true;
	const isNewCallingSupported = version && version.majorVersion >= NewCallingVersion;
	if (isNewCallingSupported && isCallingEnabled){
		const roles = getUserCallRoles(self, contact);
		callContact = () => meetingContext.onCreateMeeting(roles.recipient, `${config.lname}, ${config.fname}`, roles.participants, roles.administrators);
		useOldCalling = false;
	}

	const disableVoice = settings.voice || !isAvailable;
	const disableVideo = settings.video || (useOldCalling && !isAvailable);

	if (status === 1) {
		return (
			<Contact contact={contact} inactive={!isAvailable} key={id} onPress={openProfile} teal showAvailable screen={screen} margined backgroundColor={color}>
				{useOldCalling && (<CallButton onPress={disableVoice ? null : voiceCallContact}>
					<FontAwesomeIcon icon={disableVoice ? faPhoneSlash : faPhone} color="#004A52" size={32} />
					{renderMissedVoiceCall}
				</CallButton>)}

				<CallButton onPress={disableVideo ? null : callContact}>
					<FontAwesomeIcon icon={disableVideo ? faVideoSlash : faVideo} color="#004A52" size={32} />
					{useOldCalling && renderMissedVideoCall}
				</CallButton>
			</Contact>
		)
	} else {
		return (
			<Contact contact={contact} inactive={!isAvailable} key={id} onPress={openProfile} contrast showAvailable screen={screen} margined backgroundColor={color}>
				{useOldCalling && (<CallButton onPress={disableVoice ? null : voiceCallContact}>
					<FontAwesomeIcon icon={disableVoice ? faPhoneSlash : faPhone} color="#FFFFFF" size={32} />
					{renderMissedVoiceCall}
				</CallButton>)}

				<CallButton onPress={disableVideo ? null : callContact}>
					<FontAwesomeIcon icon={disableVideo ? faVideoSlash : faVideo} color="#FFFFFF" size={32} />
					{useOldCalling && renderMissedVideoCall}
				</CallButton>
			</Contact>
		)
	}
}

ManagedContact.contextTypes = {
	dispatch: PropTypes.func,
	config: PropTypes.object
};
