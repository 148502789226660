import React from "react";
import {
	View
} from "react-native";
import PropTypes from "prop-types";
import Style from "@aetonix/styles";
import StyledText from "ae-base/Base/StyledText";
import StyledIcon from "ae-base/Base/StyledIcon";
import ResponsiveStylesheet from "react-native-responsive-stylesheet";
import SquareButton from "ae-base/Base/SquareButton";
import StyledButton from "ae-base/Base/StyledButton";
import VictoryGraph from "../../HealthIndicators/VictoryGraph";
import PreceedingMeasure from "./PreceedingMeasure";
import { useMeasureUnits } from "@aetonix/hooks";
import { parseStatus } from "../../../utils/ParseStatus";
import { LONG_LANGS } from "../../../utils/LongLangs";

var styles = ResponsiveStylesheet.createSized("min-direction", {
	0: {
		column: {
			flexDirection: "column",
			alignItems: "center"
		},
		dateLabel: {
			top: -6
		},
		row: {
			flexDirection: "row"
		},
		today: {
			margin: Style.layout.marginSlim,
			flexDirection: "column",
			maxWidth: Style.imageSize.large,
			justifyContent: "center",
			alignSelf: "center"
		},
		activityContainer: {
			flexDirection: "column",
			justifyContent: "space-between"
		},
		precedingdaysContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center"
		},
		weight: {
			borderRadius: Style.layout.paddingSlim,
			backgroundColor: Style.colors.dark,
			borderColor: Style.colors.black,
			marginVertical: Style.layout.marginSmall,
			alignSelf: "stretch"
		},
		latest: {
			borderWidth: Style.layout.marginSlim,
			margin: Style.layout.marginSlim,
			width: Style.imageSize.quitesmall,
			height: Style.imageSize.quitesmall,
			justifyContent: "center"
		},
		square: {
			borderStyle: "solid",
			borderRadius: 10,
			borderWidth: Style.layout.marginSmall,
			backgroundColor: "white",
			alignSelf: "center"
		},
		green: {
			borderColor: Style.colors.primary,
		},
		enternew: {
			borderRadius: Style.layout.paddingSlim,
			backgroundColor: Style.colors.secondary,
			borderColor: Style.colors.black,
			marginVertical: Style.layout.marginSmall,
			alignItems: "center",
			textAlign: "center",
		},
		padded: {
			alignSelf: "center",
			color: '#4A959B'
		},
		title: {
			marginTop: Style.layout.marginSlim,
			marginBottom: Style.layout.marginSlim,
			alignItems: "center"
		},
		container: {
			flex: 1,
			padding: Style.layout.marginSmall
		},
		margined: {
			flex: 1
		},
		center: {
			alignSelf: "center",
			padding: Style.layout.paddingContent,
			justifyContent: "center"
		},
		info: {
			position: "absolute",
			top: 0,
			right: 0,
			paddingHorizontal: Style.layout.marginSmall
		},
		red: {
			borderColor: Style.colors.alert
		},
		greenStatus: {
			borderColor: Style.colors.green,
		},
		yellow: {
			borderColor: Style.colors.yellow
		},
		victoryGraphContainer: {
			flex: 1,
			justifyContent: "center",
		},
		padding:{
			alignSelf: "center",
		}
	},
});

export default function MyHealthComponent (props, context){
	const { title } = props;
	var model = props.model;
	var section = props.section;
	var xaxis = props.xaxis;
	var yaxis = props.yaxis;
	var bar = props.bar;
	var secondaryBar = props.secondaryBar === undefined ? bar : props.secondaryBar;
	var activeModel = props.activeModel;
	var entries = activeModel.cumulative || [];
	var reverseEntries = props.reverseEntries || []; // needed for chart
	var secondaryData = props.secondaryGraph || [];
	var short = activeModel.short || [];
	var latestReading = props.latestReading;
	var latestSecondaryReading = props.latestSecondaryReading;
	var textformat = props.textformat;
	const language = model.personal.language;

	var settings = (model.settings || {}).myhealth_sections || {};

	var localize = context.localize;
	var dispatch = context.dispatch;
	var units = props.units || localize(section + ".rightLabel");

	const { weightUnits } = useMeasureUnits();

	var latestTitle = localize("glucose.latest") + " (" + units + ")";
	var trendTitle = short.length ? localize("glucose.trend") + " (" + units + ")" : null;
	var enter_new = localize("glucose.manual_entry");
	var pageTitle = props.title ? props.title : localize(section + ".status");
	var openEdit = dispatch("newreading:trigger:show");
	var nodata = localize("activity.nodata");
	var readFromDevice = props.readFromDevice;
	var style_today = [styles.square, styles.latest];
	var weight = model.weight && model.weight.cumulative[0] ? model.weight.cumulative[0]["history"][weightUnits] : "0";
	var currentWeight = localize("weight.title") + ": " + weight + " " + weightUnits;
	var openWeight = dispatch("atouchawaymobile:trigger:page", {
		page: "weight"
	});

	var manualEntrySetting = settings[section + "_manualentry"];
	if (section === "customindicator") manualEntrySetting = settings[section + "s_manualentry"];

	if (entries.length && entries[0].improved && section === "activity" ) {
		style_today.push(styles.green);
	}
	else if (entries.length && !entries[0].improved && section === "activity") {
		style_today.push(styles.red);
	}
	else {
		style_today.push(styles.green);
	}

	var weightGraphic = section === "bloodpressure" ? (
		<StyledButton contrast emphasized stretch style={styles.weight} title={currentWeight} onPress={openWeight} />
	) : null;

	var newentryGraphic = manualEntrySetting ? null : <StyledButton emphasized stretch style={styles.enternew} color ={"#237B80"} contrast title={enter_new} onPress={openEdit} />;

	var todayGraphic = null;
	// Sets up the format to the 'today' button
	if (entries.length) {
		var date = section === "activity" ? entries[entries.length - 1].latest_time : new Date(entries[0].updated_at);
		var time = time_parse(date, true);
		var created = created_date_label(time.time, time.month, time.day);
		var checkComment = section === "activity" ? entries[entries.length - 1].comment : entries[0].history.comment;
		if(section === "bloodoxygen") checkComment = entries[0].history.comment || entries[0].history.exertion || entries[0].history.oxygen;
		var history = section === "activity" ? entries[entries.length - 1] : entries[0].history;
		var showComment = dispatch("comment:trigger:show", {
			data: history,
			type: section
		});
		var onpress = checkComment ? showComment : null;
		var info = checkComment ? (
			<StyledIcon style={styles.info} color ={"#237B80"} name="info" size={Style.text.standard} />
		) : null;

		var status = section === "activity" ? entries[entries.length - 1].status : entries[0].history.status;
		if(section === "bloodoxygen"){
			var heartRateStatus = entries[0].history.heartRateStatus;
			var oxygenSaturationStatus = entries[0].history.oxygenSaturationStatus;
			status = heartRateStatus;
			if(oxygenSaturationStatus > heartRateStatus)
				status = oxygenSaturationStatus;
		}

		if(section === "bloodpressure"){
			var bpPulserateStatus = entries[0].history.bpPulserateStatus ?? -1;
			var bpStatus = entries[0].history.status;
			status = Math.max(bpPulserateStatus, bpStatus);
		}

		if(status === 0)
			style_today.push(styles.greenStatus);
		else if(status === 1)
			style_today.push(styles.yellow);
		else if(status === 2)
			style_today.push(styles.red);

		todayGraphic = (
			<View style={styles.today}>
				<StyledText style={styles.padded} smallHeading>
					{latestTitle}
				</StyledText>
				<SquareButton
					title={created.time}
					secondaryTitle={created.date}
					text={latestReading}
					secondaryText={latestSecondaryReading}
					iconSize={Style.text.large}
					textSize={Style.text.smallHeading}
					style={style_today}
					clear
					onPress={onpress}
				>
					{info}
					<StyledText style={styles.padded} size={LONG_LANGS.includes(language) ? Style.text.standard : Style.text.smallHeading}>{parseStatus(status, localize)}</StyledText>
				</SquareButton>
				{weightGraphic}
				{newentryGraphic}
				{readFromDevice}
			</View>
		);
	}
	else {
		todayGraphic = (
			<View style={styles.today}>
				<StyledText style={styles.padded} smallHeading>
					{latestTitle}
				</StyledText>
				<SquareButton
					title={" "}
					text={" "}
					iconSize={Style.text.large}
					textSize={Style.text.smallHeading}
					style={style_today}
					clear
				/>
				{newentryGraphic}
				{readFromDevice}
			</View>
		);
	}

	var trendGraphic = (short.length) ? (
		<PreceedingMeasure short={short} section={section} textformat={textformat} language={language}/>
	) : null;

	const getYAxisLabel = (sectionForGraph, graphTitle, graphUnit) => {
		return title ? `${graphTitle} (${units})` : localize(`graphlabels.${sectionForGraph}.yaxis`, { units: graphUnit });
	};

	var longTermGraphic = (entries.length > 5) && hasValidY(reverseEntries) ? (
		<View style={styles.victoryGraphContainer}>
			<VictoryGraph
				data={reverseEntries}
				xaxis={xaxis}
				yaxis={yaxis}
				bar={bar}
				xAxisLabel={localize("graphlabels." + section + ".xaxis", {
					units: units
				})}
				yAxisLabel={getYAxisLabel(section, title, units)}
				padYLabel={section === "activity" || section === "bloodoxygen"}
				trend={section === "bloodpressure"}
				isATA
			/>
		</View>
	) : null;

	const getGraphTitle = () => {
		if (section === "customindicator") {
			return localize("graphlabels." + section + ".title", {
				customIndicatorName: pageTitle
			});
		} else {
			return localize("graphlabels." + section + ".title", {
				units: units
			});
		}
	};

	var graphTitle = entries.length > 5 ? (
		<StyledText style={styles.padded} smallHeading>
			{getGraphTitle(section, title, units)}
		</StyledText>
	) : null;

	var longTermGraphic2 = section === "bloodoxygen" && (entries.length > 5) && hasValidY(secondaryData) ? (
		<View style={styles.victoryGraphContainer}>
			<VictoryGraph
				data={secondaryData}
				xaxis={xaxis}
				yaxis={yaxis}
				bar={bar}
				xAxisLabel={localize("graphlabels.pulserate.xaxis")}
				yAxisLabel={localize("graphlabels.pulserate.yaxis")}
				isATA
			/>
		</View>
	) : null;

	if(!longTermGraphic2 && secondaryData.length > 5 && hasValidY(secondaryData)) {
		longTermGraphic2 = (
			<View style={styles.victoryGraphContainer}>
				<VictoryGraph
					data={secondaryData}
					xaxis={xaxis}
					yaxis={yaxis}
					bar={secondaryBar}
					xAxisLabel={props.secondaryGraphXLabel}
					yAxisLabel={props.secondaryGraphYLabel}
					isATA
				/>
			</View>
		);
	}

	var graphTitle2 = section === "bloodoxygen" && entries.length > 5 ? (
		<StyledText style={styles.padded} smallHeading>
			{localize("graphlabels.pulserate.title")}
		</StyledText>
	) : null;

	if(!graphTitle2 && secondaryData.length > 5) {
		graphTitle2 = (<StyledText style={styles.padded} smallHeading>
			{props.secondaryGraphTitle}
		</StyledText>);
	}

	var multidayContainer = entries.length ? (
		<View >
			<StyledText style={styles.padded} smallHeading>
				{trendTitle}
			</StyledText>
			<View style={styles.precedingdaysContainer}>
				{trendGraphic}
			</View>
			{graphTitle}
			{longTermGraphic}
			{graphTitle2}
			{longTermGraphic2}
		</View>
	) : <StyledText style={styles.center} smallHeading contrast>{nodata}</StyledText>;

	return (
		<View style={styles.container}>
			<View style={styles.title}>
				<StyledText tertiary style={{color:'#4A959B'}} large>{pageTitle}</StyledText>
			</View>
			<View style={styles.activityContainer}>
				{todayGraphic}
				{multidayContainer}
			</View>
		</View>
	);
}

function created_date_label(time, month, day) {
	return {
		time: time,
		date: month + "-" + day
	};
}

function time_parse(data, forBoxGraphic) {
	var date = new Date(data);
	var month = date.getMonth() + 1;
	var day = date.getDate();
	day = two_digit(day);
	month = two_digit(month);
	var hours = two_digit(date.getHours());
	var minutes = two_digit(date.getMinutes());
	var seconds = forBoxGraphic ? "" : ":" + two_digit(date.getSeconds());
	var time = hours + ":" + minutes + seconds;

	return {
		month: month,
		day: day,
		time: time
	};
}

function two_digit(data) {
	var item = (data < 10)
		? "0" + data
		: data;
	return item;
}

function isValidNumber(value) {
	if (value === null || value === undefined) {
	  return false;
	}
	return !isNaN(value) && !isNaN(Number(value));
  }
  
const hasValidY = (data) => data.some(item => {
	return isValidNumber(item.y) || isValidNumber(item.cumulative)
});

MyHealthComponent.contextTypes = {
	dispatch: PropTypes.func,
	localize: PropTypes.func
};
